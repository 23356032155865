window.$ = require("jquery")
require("bootstrap")
require("metismenu")
require("simplebar")
require("node-waves")
require("feather-icons")
require("pace-js")



window.ApexCharts = require('apexcharts');

window.feather = require('feather-icons');


import("../../js/pages/dashboard.init")

import("../../js/app")



window.addEventListener('load', function() {
        // Fetch all the forms we want to apply custom Bootstrap validation styles to
        var forms = document.getElementsByClassName('needs-validation');
        // Loop over them and prevent submission
        var validation = Array.prototype.filter.call(forms, function(form) {
            form.addEventListener('submit', function(event) {
                if (form.checkValidity() === false) {
                    event.preventDefault();
                    event.stopPropagation();
                }
                form.classList.add('was-validated');
            }, false);
        });
    }, false);
